.title{
    font-size:1.3em;
    font-weight: 300;
}

.margin-auto{
    margin: auto
}

.price{
    font-size: 2em;
}

#productList{
    display:inline-block; 
    margin-top: 150px;
}

