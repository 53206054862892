.heading{
    font-size:2em;
}

li:nth-child(odd){
    background-color: rgba(0,0,0,0.04);

}


li{
    margin: .5em 0;
    position: relative;
}

li div{
    padding: 1em;
    display: inline-block;
}

.w-60{ width: 60%;}

.relative{
    position: relative;
}

.absolute-right{
    position: absolute;
    right: 0;
    top:0;
}

.list-item{
     line-height: 1.6em;
}